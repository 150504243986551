
import "./product-list-v1"
import "./product-card-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class ProductSelectV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.errorEl = this.element.querySelector(".errorPlaceholder")
  }

  setError(error) {
    this.errorEl.innerText = error
  }

  clearError() {
    this.errorEl.innerText = ""
  }



}

registerComponent('ProductSelect/V1', ProductSelectV1)
window["ProductSelectV1"] = ProductSelectV1

