
import OrderCart from "./order-cart-v1"
import "./product-item-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class ProductListV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.productItems = this.getComponents("ProductItem/V1");
  }

  updateSelectedVariant(productItem) {
    if(this.productItems && this.productItems.length > 0 && this.productItems[0].selectStyle === "single") {
      this.productItems.forEach((otherProductItem) => {
        if(otherProductItem != productItem) {
          otherProductItem.unselectVariant()
        }
      })
    }
  }



}

registerComponent('ProductList/V1', ProductListV1)
window["ProductListV1"] = ProductListV1

