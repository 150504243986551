

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class MultiStepV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.step = 0
    this.stepCount = parseInt(this.stepCount)
    this.steps = this.element.querySelectorAll(".elMultiStepBody")
    this.stepHeaders = this.element.querySelectorAll(".elMultiStepHeader")
    this.stepsState = new Array(this.stepCount).fill(null).map( _ => ({state: "incomplete"}))
    this.progressBar = this.element.querySelector(".elMultiStepProgressValue")

    this.setActiveStep(this.step)

    const buttons = this.element.querySelectorAll('[data-page-element="Button/V1"] a[href="#!next-step"]')
    buttons.forEach((button) => {
      const href = button.getAttribute('href')
      if(href === "#!next-step") {
        const that = this
        button.addEventListener('click', (evt) => {
          evt.preventDefault()
          if(button.dataset.disabled == 'true') { return }
          this.next()
        })
      }
      else if(href === "#!prev-step") {
        button.addEventListener('click', (evt) => {
          evt.preventDefault()
          if(button.dataset.disabled == 'true') { return }
          this.prev()
        })
      }
      else if(href === "#!open-step") {
        var buttonStep = button.closest('.elCheckoutStepEditButton').dataset.stepNumber
        button.addEventListener('click', (evt) => {
          evt.preventDefault()
          if(button.dataset.disabled == 'true') { return }
          if(this.getClosestComponent('Checkout/V1').validateMultiStep(this.step)) {
            this.stepsState[this.step].state = "completed";
          } else {
            this.stepsState[this.step].state = "error";
          }
          this.curr(buttonStep)
        })
      }
    })

    this.stepHeaders.forEach((header) => {
      var stepNumber = header.dataset.stepNumber
      header.addEventListener('click', (evt) => {
        this.curr(stepNumber)
      })
    })
  }

  hideCurrentStep() {
    this.steps[this.step].setAttribute("data-active-step", false)
  }

  showCurrentStep() {
    this.setActiveStep(this.step)
    this.steps[this.step].setAttribute("data-active-step", true)
    $([document.documentElement, document.body]).animate({
      scrollTop: $(this.element).offset().top - 50
    }, 200);
  }

  changeStep(newStep) {
    this.hideCurrentStep()

    const currentStep = this.step
    newStep = Math.max(Math.min(newStep, this.steps.length - 1),0)

    const res = this.getClosestComponent('Checkout/V1').validateMultiStep(this.step)
    let promiseResult
    if (typeof res === 'object' && typeof res.then === 'function' ) {
      promiseResult = res
    } else {
      promiseResult = Promise.resolve(res)
    }

    const nextStepState = this.stepsState[newStep].state
    const notCompletedStep = nextStepState != 'completed'
    
    if(notCompletedStep) {
      //NOTE: When the newStep is not completed yet we have to wait the validation
      // before go into the next step
      promiseResult
        .then((result) => this.processValidation(currentStep, result))
        .then((stepIsValid) => {
          if(stepIsValid) {
            this.hideCurrentStep()
            this.step = newStep
            this.stepsState[newStep].state = "dirty"
            this.updateStepsState()
            this.showCurrentStep()
          } else {
            if ($('.elInputError').length == 0) return
            $('.elInputError').trigger('focus')
            $([document.documentElement, document.body]).animate({
              scrollTop: $(".elInputError").offset().top - 50
            }, 200);
          }
        });
    } else {
      //NOTE: When the newStep is a step already visited we can go to it
      //immediately and validate the current step async 
      promiseResult
        .then((result) => this.processValidation(currentStep, result));

      this.step = newStep
      this.updateStepsState()
    }

    this.showCurrentStep()
  }

  processValidation(step, stepIsValid) {
    this.stepsState[step].state = stepIsValid ? "completed" : "error";
    this.updateStepsState()
    return stepIsValid
  }
  
  updateStepsState() {
    this.progressBar.setAttribute("width", 100/this.steps.length * (this.step + 1) + '%')
    this.stepsState.forEach((stepState, index)=> {
      this.element.querySelectorAll(`.elMultiStepHeader[data-step-number="${index + 1}"]`).forEach((el) => {
        el.setAttribute("data-step-state", stepState.state);
        if(el.children.length > 0){
          el.children[0].setAttribute("data-step-state", stepState.state)
        }
      })
    })
  }

  next() {
    this.changeStep(this.step + 1)
  }

  prev() {
    this.changeStep(this.step - 1)
  }

  curr(currStep) {
    this.changeStep(parseInt(currStep) - 1)
  }

  setActiveStep(index) {
    const activeSteps = Array.from(this.stepHeaders).filter(header => {
      return header.dataset.stepNumber === (index + 1).toString()
    });

    this.stepHeaders.forEach(step => step.classList.remove('elMultiStepActive'));
    activeSteps.forEach(step => step.classList.add('elMultiStepActive'));
  }



}

registerComponent('MultiStep/V1', MultiStepV1)
window["MultiStepV1"] = MultiStepV1

