

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CheckoutStepButtonV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

// Line 8: Adds the button when the checkout is in the saved state (saved submit)
// Line 14: Adds the button on the last step when the checkout has 3 steps (submit button)
// Line 18: Adds the buttons for each step of checkout (step button)
// Line 23: Adds the button when the checkout is a one step (submit button)



}

registerComponent('CheckoutStepButton/V1', CheckoutStepButtonV1)
window["CheckoutStepButtonV1"] = CheckoutStepButtonV1

