
import OrderCart from "./order-cart-v1"
import "./radio-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class MultiplePaymentsV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

FIELD_TITLES = {
    fullName: 'Full Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    emails: 'Email',
  }
  devices = { 
    'payment-card': undefined,
    'apple-pay': undefined,
    ach: undefined,
    'digital-wallet': undefined
  }
  
  mount () {
    this.enabled_payments = window.globalResourceData.enabledPaymentsLegacy ?? []
    this.preparePaypalExtraData()
    this.prepareRebillyConfig()
    // const Rebilly = globalThis.Rebilly
    Rebilly.initialize(this.rebillyConfig)
    this.addGlobalListeners()
        
    const form = document.querySelector('#cfAR')
    form.querySelectorAll('input[data-rebilly]').forEach((cfARInput) => {
      const dataRebillyAttribute = cfARInput.getAttribute('data-rebilly')
      const dataCfFormField = cfARInput.getAttribute('data-cf-form-field')
      const pageInputField = document.querySelector('input' + '[name="' + dataCfFormField + '"]')
      if (!pageInputField) {
        cfARInput?.removeAttribute('data-rebilly')
      } else {
        pageInputField?.setAttribute('data-rebilly', dataRebillyAttribute)
      }
    })
    this.setupPayment()
  }

  checkPaypalReady() {
    // NOTE: Feel free to override this method
   return true 
  }

  preparePaypalExtraData() {
    this.paypalExtraData = {
      onInit: (data, actions) => {
        // Called when button UI is loaded
        // console.info('PayPal Init', data, actions)
        this.element.querySelector('.paypal-content .elSpinnerWrapper').dataset.loader = false
      },
      onApprove: (data, actions) => {
        // Called after customer approves the transaction on paypal.com. Same as framepay.on('token-ready')
        console.info('PayPal approve', data, actions)
        this.element.querySelector('.paypal-content').classList.add('hide')
        $('[href="#submit-form"]').show()
      },
      onError: (data, actions) => {
        // Called when an error has occurred
        console.info('There was an error', data, actions)
      },
      onCancel: (data, actions) => {
        // Called when customer clicks cancel button
        console.info('Purchase was canceled', data, actions)
      },
      onClick: (data, actions) => {
        return this.checkPaypalReady().then((res) => {
          if (!res) {
            return actions.reject()
          } else {
            return actions.resolve()
          }
        })
      },
      // onShippingChange: (data, actions) => {
      //   // Called when customer changes the shipping address
      //   console.info('PayPal shipping change', data, actions)
      //   const event = new CustomEvent('payments:paypal:shipping_changed')
      //   window.dispatchEvent(event)
      // },
    }
  }

  prepareRebillyConfig() {
    const rebillyKeys = document.getElementById('payment-gateway-keys')
    const publishableKey = rebillyKeys.getAttribute('data-rebilly-publishable-key')
    const organizationId = rebillyKeys.getAttribute('data-rebilly-organization-id')
    const websiteId = rebillyKeys.getAttribute('data-rebilly-website-id')
    const currency = rebillyKeys.getAttribute('data-rebilly-currency')
    this.rebillyConfig = {
      // TODO (Payment Gateway): we need to retrieve this from back-end and save it in the HTML
      publishableKey: publishableKey,
      organizationId: organizationId,
      kountAccountId: '700000', // This is for capturing kount fraud sessions
      websiteId: websiteId,
      transactionData: {
        currency: currency || 'USD',
        amount: 1,
        label: 'Product Purchase',
      },
    }
    if (this.enabled_payments.includes('digital-wallet')) {
      this.rebillyConfig.digitalWallet  = {
        // TODO (Payment Gateway): This price has to be calculated based on the user cart
        transactionData: {
          amount: 1,
          currency: 'USD',
          countryCode: 'US',
          label: 'My Cool product',
        },
        allowedCardBrands: [
          Rebilly.digitalWallet.GooglePayAllowedCardNetworks.Mastercard,
          Rebilly.digitalWallet.GooglePayAllowedCardNetworks.Visa,
        ],
        // TODO (Payment Gateway): This merchant information has to be retrieved from DB
        merchantConfig: {
          merchantName: '3stec',
          merchantOrigin: 'mycf.myclickfunnels.test',
        },
        googlePayDisplayOptions: {
          buttonColor: 'white',
          buttonType: 'long',
        },
      }
    }
  }

  addGlobalListeners() {
    const Rebilly = globalThis.Rebilly
    
    // NOTE: this is only here to allow standalone payment elements work
    window.addEventListener('payments:submit-failed', () => {
      this.submitted = false
      if (this.selectedPaymentMethod == 'paypal') {
        $('[href="#submit-form"]').hide()
        this.element.querySelector('.paypal-content').classList.remove('hide')
      }
    })

    // NOTE: this is only here to allow standalone payment elements work
    window.addEventListener('payments:clear-errors', () => {
      this.clearErrors()
    })

    // NOTE: this is only here to allow standalone payment elements work
    window.addEventListener('payments:set-token-error', (evt) => {
      const error = evt.detail.error
      this.setTokenErrors(error)
    })

    // NOTE: this is only here to allow standalone payment elements work
    window.addEventListener('payments:update-rebilly-config', (evt) => {
      this.updateRebillyConfig(evt.detail.orderSummary)
    })

    // The token is ready, and we can submit the form
    // This listener is used for express payment methods like paypal, applePay, googlePay
    Rebilly.on('token-ready', (data) => {
      console.log('FramePay success', data)
      globalThis.parent.postMessage('success', '*')
      const form = document.querySelector('#cfAR')
      ;(form.querySelector('input[data-rebilly="token"]')).value = data.id
      this.clearErrors()
      window.dispatchEvent(new CustomEvent('payments:token-ready'))
    })
    Rebilly.on('ready', () => {
      this.mountRebillyElement(this.selectedPaymentMethod);
    })
  }
  
  setupPayment() {
    const submitFormButton = document.querySelector('[href="#submit-form"]')
    if (submitFormButton) {
      this.element.querySelectorAll('.pai-submit').forEach((element) => {
        element.remove()
      })
    }

    this.element.querySelectorAll('.pai-submit').forEach((element) => {
      element.addEventListener('click', () => {
        if (!this.submitted) {
          this.submitted = true
          this.unmountUnusedPaymentMethods()
          window.rebillyProcessOrder();
        }
      })
    })

    const select = this.element.querySelector('.elSuperSelect[name="payment_id"]')
    const paymentMethods = this.element.querySelector('.pai-payment-methods-inner')
    const icon = this.element.querySelector('.elSuperSelectIcon')
    if (select) {
      const $orderFormInput = $('#cfAR input[data-order-form]')
      $orderFormInput.val('true')
      select?.addEventListener('change', (evt) => {
        if (evt.target.value.length == 0) {
          paymentMethods.classList.remove('hide')
          icon.classList.add('hide')
          $orderFormInput.val('false')
        } else {
          paymentMethods.classList.add('hide')
          icon.classList.remove('hide')
          $orderFormInput.val('true')
        }
      })
    }

    this.element.querySelectorAll('[name="rebilly-payment-method"]').forEach((element) => {
      element.addEventListener('click', (evt) => {
        const paymentType = evt.target.value
        if (!Rebilly.initialized) Rebilly.initialize(this.rebillyConfig)
        this.selectedPaymentMethod = paymentType
        this.unmountUnusedPaymentMethods()
        this.mountRebillyElement(this.selectedPaymentMethod)
        this.updatedRebillyPaymentMethod()
        // NOTE: this is only here to allow standalone payment elements work
        globalThis.paymentsSelectedPaymentMethod = paymentType
      })
    })

    // Checks first available input
    const radioInputButton = this.element.querySelector('[name="rebilly-payment-method"]');
    if (radioInputButton) {
      radioInputButton.checked = true;
      this.updatedRebillyPaymentMethod(radioInputButton);
      this.selectedPaymentMethod = radioInputButton.value
    }
  }

  getSelectedPaymentType() {
    return this.element.querySelector('[name="rebilly-payment-method"]').value
  }

  rebillyDestroyAllPayments() {
    if (this.devices['payment-card']?.number) {
      this.devices['payment-card'].number.destroy()
      this.devices['payment-card'].cvv.destroy()
      this.devices['payment-card'].date.destroy()
      this.devices['payment-card'] = undefined
    }
    if (this.devices.ach) {
      this.devices.ach.type.destroy()
      this.devices.ach.number.destroy()
      this.devices.ach.routing.destroy()
      this.devices.ach = undefined
    }
    if (this.devices['apple-pay']) {
      this.devices['apple-pay'].destroy()
      this.devices['apple-pay'] = undefined
    }
    if (this.devices['digital-wallet'] == true) {
      this.devices['digital-wallet'].destroy()
      this.devices['digital-wallet'] = undefined
    }
  }

 clearErrors() {
    this.element.querySelectorAll('.pai-payment-field-error-list').forEach((error) => {
      error.innerHTML = ''
    })
    this.element.querySelector('.pai-payment-global-errors-list').classList.remove('is-active')
    this.element.querySelectorAll('.pai-payment-field-error-list.cf-dynamic-input-error').forEach((error) => {
      error.remove()
    })
  }

  updatedRebillyPaymentMethod() {
    const inputElements = this.element.querySelectorAll("[name='rebilly-payment-method']")
    inputElements.forEach((inputElement) => {
      const mountElement = this.element.querySelector('.' + inputElement.value + '-mount')
      const contentElement = this.element.querySelector('.' + inputElement.value + '-content')
      const parentField = inputElement.closest('.pai-payment-field')

      inputElement.checked ? parentField.classList.add('is-active') : parentField.classList.remove('is-active')
      contentElement.style.display = inputElement.checked ? 'block' : 'none'
      if (mountElement) mountElement.style.display = inputElement.checked ? 'block' : 'none'
      if (inputElement.checked && inputElement.value == 'paypal') {
        $('[href="#submit-form"]').hide()
      } else if (inputElement.checked && inputElement.value != 'paypal') {
        $('[href="#submit-form"]').show()
      }
    })
  }

  setCardErrors(event, errorField) {
    const paymentCardWrapper = this.element.querySelector('.payment-card-fields-container')
    if (event.error) {
      errorField.classList.add('is-active')
      errorField.innerText = event.error.message
      paymentCardWrapper.classList.add('payment-card-fields-container-invalid')
    } else if (event.valid) {
      errorField.classList.remove('is-active')
      paymentCardWrapper.classList.remove('payment-card-fields-container-invalid')
    }
  }

  unmountUnusedPaymentMethods() {
    const paymentType = this.selectedPaymentMethod
    if (paymentType != 'payment-card' && this.devices['payment-card']?.number) {
      this.devices['payment-card'].number.unmount()
      this.devices['payment-card'].cvv.unmount()
      this.devices['payment-card'].date.unmount()
      this.devices['payment-card'] = undefined
    }
    if (paymentType != 'apple-pay' && this.devices['apple-pay']) {
      this.devices['apple-pay'].unmount()
      this.devices['apple-pay'] = undefined
    }
    if (paymentType != 'paypal' && this.devices.paypal) {
      this.devices.paypal.unmount()
      this.devices.paypal = undefined
    }
    if (paymentType != 'ach' && this.devices.ach) {
      this.devices.ach.type.unmount()
      this.devices.ach.number.unmount()
      this.devices.ach.routing.unmount()
      this.devices.ach = undefined
    }
  }

  mountRebillyElement(paymentType) {
    const paymentRadioInputs = [...(this.element.querySelectorAll("[name='rebilly-payment-method']"))]
    const paymentCardWrapper = this.element.querySelector('.payment-card-fields-container')
    const Rebilly = globalThis.Rebilly
    if (!Rebilly.initialized) return
    if (paymentType == 'payment-card' && !this.devices['payment-card']?.number) {
      this.devices['payment-card'] = {}
      this.devices['payment-card'].number = Rebilly.card.mount('.payment-card-number-mount', 'cardNumber')
      this.devices['payment-card'].cvv = Rebilly.card.mount('.payment-card-cvv-mount', 'cardCvv')
      this.devices['payment-card'].date = Rebilly.card.mount('.payment-card-date-mount', 'cardExpiration')

      this.devices['payment-card'].number.on('change', (event) => {
        const field = this.element.querySelector('.payment-card-number-mount').closest('.pai-payment-field')
        const errorField = field.querySelector('.pai-payment-field-error-list')
        this.setCardErrors(event, errorField)
      })
      this.devices['payment-card'].number.on('focus', (event) => {
        paymentCardWrapper.classList.add('payment-card-fields-container-focus')
        window.CheckoutV1ClearSubmitErrors()
      })
      this.devices['payment-card'].number.on('blur', (event) => {
        paymentCardWrapper.classList.remove('payment-card-fields-container-focus')
      })
      this.devices['payment-card'].cvv.on('change', (event) => {
        const field = this.element.querySelector('.payment-card-cvv-mount').closest('.pai-payment-field')
        const errorField = field.querySelector('.pai-payment-field-error-list')
        this.setCardErrors(event, errorField)
      })
      this.devices['payment-card'].cvv.on('focus', (event) => {
        paymentCardWrapper.classList.add('payment-card-fields-container-focus')
        window.CheckoutV1ClearSubmitErrors()
      })
      this.devices['payment-card'].cvv.on('blur', (event) => {
        paymentCardWrapper.classList.remove('payment-card-fields-container-focus')
      })
      this.devices['payment-card'].date.on('change', (event) => {
        const field = this.element.querySelector('.payment-card-date-mount').closest('.pai-payment-field')
        const errorField = field.querySelector('.pai-payment-field-error-list')
        this.setCardErrors(event, errorField)
      })
      this.devices['payment-card'].date.on('focus', (event) => {
        paymentCardWrapper.classList.add('payment-card-fields-container-focus')
        window.CheckoutV1ClearSubmitErrors()
      })
      this.devices['payment-card'].date.on('blur', (event) => {
        paymentCardWrapper.classList.remove('payment-card-fields-container-focus')
      })
    } else if (paymentType == 'ach' && this.devices.ach) {
      this.devices.ach = {}
      this.devices.ach.bban = Rebilly.bban.mount('#bank-account-type', 'accountType')
      this.devices.ach.number = Rebilly.bban.mount('#bank-account-number', 'accountNumber')
      this.devices.ach.routing = Rebilly.bban.mount('#bank-routing-number', 'routingNumber')
    } else if (paymentType == 'apple-pay') {
      this.devices['apple-pay'] = Rebilly.applePay.mount('#apple-pay-mount')
      const applePayButton = this.element.querySelector('#rebilly-apple-pay-button')
      if (this.ApplePaySession || applePayButton) {
        const applePayUnavailable = this.element.querySelector('#apple-pay-unavailable')
        if (applePayUnavailable) applePayUnavailable.remove()
      } else {
        const paragraph = document.createElement('p')
        paragraph.setAttribute('id', 'apple-pay-unavailable')
        paragraph.style.textAlign = 'center'
        paragraph.innerText = 'ApplePay is unavailable at this time.'
        const applePayUnavailable = this.element.querySelector('#apple-pay-unavailable')
        if (!applePayUnavailable) document.querySelector('#apple-pay-mount').append(paragraph)
      }
    } else if (paymentType == 'paypal') {
      this.element.querySelector('.paypal-content .elSpinnerWrapper').dataset.loader = true
      this.devices.paypal = Rebilly.paypal.mount('#paypal-mount', { extraData: this.paypalExtraData })
    } else if (paymentType == 'digital-wallet') {
      const form = document.querySelector('#cfAR')
      this.devices['digital-wallet'] = Rebilly.digitalWallet.mount('.digital-wallet-mount', {
        type: 'googlePay',
        form: form,
        extraData: {
          method: 'digital-wallet',
        },
      })
    }
    paymentRadioInputs.forEach((radioInput) => (radioInput.disabled = false))
  }

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&quot;')
      .replace(/\(|\)/g, '')
      .replace(/"/g, "'")
      .replace(/function/g, '')
      .replace(/__proto__/g, '')
      .replace(/prototype/g, '')
      .replace(/toString/g, '')
      .replace(/valueOf/g, '')
      .replace(/constructor/g, '')
      .replace(/script/g, '')
      .replace(/eval/g, '')
      .replace(/http/g, '')
      .replace(/"/g, "'")
      .replace(/supports/gi, '')
      .replace(/import/gi, '')
      .replace(/\{|\}/gi, '')
      .replace(/;/gi, '')
      .replace(/@import/gi, '')
      .replace(/expression/gi, '')
      .replace(/url/gi, '')
      .replace(/javascript/gi, '')
  }

  async updateRebillyConfig(orderSummary) {
    const Rebilly = globalThis.Rebilly
    if (!(this.ApplePaySession && this.enabled_payments.includes('apple-pay'))) return
    if (!Rebilly.initialized) Rebilly.initialize(this.rebillyConfig)
    if (!Rebilly._$controllerFrame$_) return

    const unescapedLabel = orderSummary.productVariants.map((el) => el.name).join(',')
    const label = this.escapeHtml(unescapedLabel)
    const currency = orderSummary.orderPreviewData.currency
    const amount = orderSummary.orderPreviewData.total
    this.rebillyConfig.transactionData = { currency, label, amount }

    try {
      await Rebilly.update(config)
    } catch (error) {
      console.log('Apple Pay error', error)
    }
  }

  setTokenErrors(error) {
    this.clearErrors()
    if (error.code === 'api-validation') {
      const rebillyFieldKeyMapping = {
        'paymentInstrument.cvv': 'cvv' 
      }
      error.invalidFields.forEach((fieldError) => {
        const key = rebillyFieldKeyMapping[fieldError.field]
        if (key) {
          const field = this.element.querySelector(`.payment-card-${key}-mount`).closest('.pai-payment-field')
          const errorField = field.querySelector('.pai-payment-field-error-list')
          this.setCardErrors({valid: false, error: {message: fieldError.message}}, errorField)
        } else {
          document.querySelectorAll('#cfAR input[data-rebilly]').forEach((formInputField) => {
            if (formInputField.type === 'hidden') return
            const rebillyFieldName = formInputField.getAttribute('data-rebilly')
            const regex = new RegExp(rebillyFieldName, 'i')
            if (regex.test(fieldError.field)) {
              const dataCfFormField = formInputField.getAttribute('data-cf-form-field')
              const pageInputField = document.querySelector(`input[name="${dataCfFormField}"]`)
              if (!pageInputField || pageInputField.type === 'hidden') return
              const errorMessage = this.capitalize(fieldError.message)

              $(pageInputField).parent().append(
                `<p class="pai-payment-field-error-list cf-dynamic-input-error is-active">
                  ${errorMessage}
                </p>`
              )
            }
          })
        }
      })
    } else if (error.details && error.details.length) {
      const rebillyPaymentGlobalErrorsList = this.element.querySelector('.pai-payment-global-errors-list')
      rebillyPaymentGlobalErrorsList.classList.add('is-active')
      let errorHTML = ''
      error.details.forEach((detail) => {
        if (typeof detail == 'object') {
          const fieldName = detail['rebilly-data'] || detail['data-rebilly']
          
            .parent()
            .append(
              `<p class="pai-payment-field-error-list cf-dynamic-input-error is-active">
                ${this.FIELD_TITLES[fieldName] || this.capitalize(fieldName)} ${detail.error}
              </p>`
            )
        } else {
          let parsedError = detail?.replaceAll('data-rebilly=', '')
          parsedError = parsedError?.replaceAll('rebilly', '')
          errorHTML += `<p> ${parsedError} </p>`
        }
      })
      rebillyPaymentGlobalErrorsList.innerHTML = errorHTML
    } else if (error && error.message) {
      const rebillyPaymentGlobalErrorsList = document.querySelector('.pai-payment-global-errors-list')
      rebillyPaymentGlobalErrorsList.classList.add('is-active')
      rebillyPaymentGlobalErrorsList.innerHTML = error.message
    }
  }

   setOrderErrors(error) {
    this.clearErrors()
    if (error && error.message) {
      const rebillyPaymentGlobalErrorsList = document.querySelector('.pai-payment-global-errors-list')
      rebillyPaymentGlobalErrorsList.classList.add('is-active')
      rebillyPaymentGlobalErrorsList.innerHTML = error.message
    }
  }

  capitalize(val) {
    return val.charAt(0).toUpperCase() + val.slice(1)
  }



}

registerComponent('MultiplePayments/V1', MultiplePaymentsV1)
window["MultiplePaymentsV1"] = MultiplePaymentsV1

