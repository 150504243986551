
import "./checkbox-v1"
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class TermsOfServiceV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    if (window.getComputedStyle(this.element).display == 'none') {
      const input = this.element.querySelector('input')
      input.setAttribute('data-prevent-submit', true)
    }
  }



}

registerComponent('TermsOfService/V1', TermsOfServiceV1)
window["TermsOfServiceV1"] = TermsOfServiceV1

